// package import
import React, { Suspense, lazy } from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
// asset import
import logo from "./logo.svg";
// css import
import "./assets/styles/global.css";
// pages lazy imports
const PageNotFound = lazy(() => import(`./pages/PageNotFound`));
const AppLoader = lazy(() => import(`./pages/AppLoader`));
const PageContainer = lazy(() => import(`./layouts/PageContainer`));
const Home = lazy(() => import(`./pages/Home/Home`));
const ExploreMore = lazy(() =>
  import("./features/Home/TCurvedProcurement/ExploreMore")
);
const AboutUs = lazy(() => import("./pages/AboutUs/AboutUs"));
const Services = lazy(() => import("./pages/Services/Services"));
const ContactUs = lazy(() => import("./pages/ContactUs/ContactUs"));
const ComingSoon = lazy(() => import("./pages/ComingSoon"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy/PrivacyPolicy"));

// router config
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<PageContainer />}>
      <Route path="*" element={<PageNotFound />} />
      <Route path="/coming-soon" element={<ComingSoon />} />
      <Route index element={<Home />} />
      <Route path="/index" element={<Home />} />
      <Route path="/explore-project" element={<ExploreMore />} />
      <Route path="/aboutus" element={<AboutUs />} />
      <Route path="/services" element={<Services />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    </Route>
  )
);
function App() {
  return <RouterProvider router={router} />;
}

export default App;
